<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",

  props: {
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      isActive: false,
    };
  },

  watch: {
    isActive(val) {
      if (val) {
        this.$emit('onActive');
      }
    }
  },

  computed: {
    href() {
      return `#${this.name.toLowerCase().replace(/ /g , '-')}`;
    }
  },

  mounted() {
    this.isActive = this.selected;
  }
}
</script>
