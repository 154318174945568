<template>
  <div>
    <div class="tabs">
      <ul>
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ 'is-active': tab.isActive }"
        >
          <a
            @click="selectTab(tab)"
          ><h2>{{ tab.name }}</h2></a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: "Tabs",


  data() {
    return {
      tabs: []
    };
  },

  methods: {
    selectTab (selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name == selectedTab.name);
      });
    }
  },

  created() {
    this.tabs = this.$children;
  },
};
</script>

<style scoped lang="scss">
.tabs {
  align-items: stretch;
  display: flex;
  margin-top: 2rem;
  font-size: 1rem;
  cursor: pointer;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  ul {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    list-style: none;
  }
  .is-active {
    background: url("/images/yellow-brushstroke.png");
    background-repeat: no-repeat;
    background-size: 100% 90%;
    padding: 8px 0;
    a {
      // border-bottom-color: #00d1b2;
    }
  }
  a {
    align-items: center;
    // border-bottom: 1px solid #dbdbdb;
    color: #2c2c2c;
    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    padding: 0.5em 1em;
    vertical-align: top;
  }
  .tab-content {
    box-sizing: border-box;
  }
}
@media (max-width: 992px){
  .tabs {
    h2 {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>

