<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div
          class="project-single col-sm-12 mar-top-0"
          style="text-align: center"
        >
          <img
            v-bind:src="
              'https://hashmasksstore.blob.core.windows.net/hashmaskspreview/' +
                revealedMaskIndex($route.params.id) +
                '.png'
            "
            style="max-width: 400px; width: 100%"
          /><br />
          <a
            v-bind:href="
              'https://hashmasksstore.blob.core.windows.net/hashmasks/' +
                revealedMaskIndex($route.params.id) +
                '.jpg'
            "
            >Download Hi-res version</a
          >
          <div class="col-sm-8 offset-sm-2 mar-top-50">
            <div class="project-title">
              <h1>#{{ $route.params.id }} - {{ name }}</h1>
            </div>
            <ul class="project-meta">
              <div class="ethereum-address-block">
                <router-link :to="{ name: 'owner', params: { id: owner } }">
                  <h2
                    class="uppercase"
                    style="font-weight: bold; margin-top: 0; color: burlywood;"
                    v-if="owner && owner.toLowerCase() === GRAVEYARD_ADDRESS"
                  >
                    GRAVEYARD
                  </h2>
                  <span
                    v-if="owner && owner.toLowerCase() !== GRAVEYARD_ADDRESS"
                    >Owned by:
                    {{ owner }}
                  </span>
                </router-link>
              </div>
            </ul>

            <div
              class="card"
              style="margin-top: 20px"
              v-if="
                this.$store.state.isWalletConnected &&
                  this.$store.state.defaultAccount == this.owner
              "
            >
              <div class="card-body" style="padding: 30px; background: #ffffff">
                <h5 class="card-title">
                  Accumulated NCT: {{ accumulatedNCT }}
                </h5>
                <h5 class="card-title">Wallet NCT: {{ nctBalance }}</h5>
                <LoadingButtonContent
                  :buyEnabled="claimEnabled"
                  :clickAction="claim"
                  :buttonText="'Claim'"
                ></LoadingButtonContent>
                <LoadingButtonContent
                  :buyEnabled="changeNameEnabled"
                  :clickAction="changeNameForm"
                  :buttonText="'Change Name'"
                ></LoadingButtonContent>
              </div>
            </div>

            <div class="card" style="margin-top: 20px" v-else>
              <div class="card-body" style="padding: 30px; background: #ffffff">
                <h5 class="card-title">
                  Accumulated NCT: {{ accumulatedNCT }}
                </h5>
              </div>
            </div>

            <div class="project-desc" v-if="mask">
              <h2>Traits</h2>

              <div class="lined thick inline-block">
                Character<br /><router-link
                  :to="{
                    name: 'gallery',
                    query: { baseCharacter: mask.baseCharacter },
                  }"
                  ><span>{{ mask.baseCharacter }}</span></router-link
                >
              </div>
              <div class="lined thick inline-block">
                Mask<br /><router-link
                  :to="{ name: 'gallery', query: { mask: mask.mask } }"
                  ><span>{{ mask.mask }}</span></router-link
                >
              </div>
              <div class="lined thick inline-block">
                Eye Color<br /><router-link
                  :to="{ name: 'gallery', query: { eyes: mask.eyes } }"
                  ><span>{{ mask.eyes }}</span></router-link
                >
              </div>
              <div class="lined thick inline-block">
                Skin Color<br /><router-link
                  :to="{ name: 'gallery', query: { skin: mask.skin } }"
                  ><span>{{ mask.skin }}</span></router-link
                >
              </div>
              <div class="lined thick inline-block">
                Item<br /><router-link
                  :to="{ name: 'gallery', query: { item: mask.item } }"
                  ><span>{{ mask.item }}</span></router-link
                >
              </div>
              <div class="lined thick inline-block">
                Background<br /><router-link
                  :to="{
                    name: 'gallery',
                    query: { background: mask.background },
                  }"
                  ><span>{{ mask.background }}</span></router-link
                >
              </div>
              <div
                v-if="mask.glyph !== 'None'"
                class="lined thick inline-block"
              >
                Glyph<br /><router-link
                  :to="{ name: 'gallery', query: { glyph: mask.glyph } }"
                  ><span>{{ mask.glyph }}</span></router-link
                >
              </div>
              <div v-if="mask.set !== 'None'" class="lined thick inline-block">
                Set<br /><router-link
                  :to="{ name: 'gallery', query: { set: mask.set } }"
                  ><span>{{ mask.set }}</span></router-link
                >
              </div>
            </div>
            <div>
              <tabs>
                <tab
                  name="Naming History"
                  :selected="true"
                  @onActive="getNameHistory"
                >
                  <div v-if="nameHistory" class="history-container">
                    <div
                      v-if="nameHistory.length"
                      class="history-block lined thick inline-block"
                    >
                      <div
                        class="history-item"
                        v-for="(item, index) in nameHistory"
                        :key="item.timestamp"
                      >
                        <hr v-if="index !== 0" />
                        {{ item.name }} <span> {{ item.timestamp }}</span>
                      </div>
                    </div>
                    <div v-else>No Data</div>
                  </div>
                  <div
                    v-else
                    class="history-container"
                    style="display: flex; justify-content: center"
                  >
                    <div class="loading-circle"></div>
                  </div>
                </tab>
                <tab name="Sale History" @onActive="getSaleHistory">
                  <div v-if="saleHistory" class="history-container">
                    <div
                      v-if="saleHistory.length"
                      class="history-block lined thick inline-block"
                    >
                      <div
                        class="history-item"
                        v-for="(item, index) in saleHistory"
                        :key="item.timestamp"
                      >
                        <hr v-if="index !== 0" />
                        {{ item.total_price }} Ξ
                        <span> {{ item.timestamp }}</span>
                      </div>
                    </div>
                    <div v-else>No Data</div>
                  </div>
                  <div
                    v-else
                    class="history-container"
                    style="display: flex; justify-content: center"
                  >
                    <div class="loading-circle"></div>
                  </div>
                </tab>
              </tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal name="nameChangeForm" width="500" height="350">
      <form v-on:submit.prevent class="popup-form" style="margin-top: 10%">
        <h3>Enter a new name</h3>
        <input
          class="form-control"
          placeholder="Enter the new name"
          type="text"
          v-model="newName"
        />
        <p class="error">{{ nameValidation }}</p>
        <button class="mint-button" v-on:click="changeName">Change Name</button>
      </form>
    </modal>
    <modal name="insufficientNCT" width="450" height="auto">
      <form v-on:submit.prevent class="popup-form">
        <h3>Insufficient NCT balance in your wallet</h3>
        <p>Please claim your NCTs and try again</p>
      </form>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import vmodal from 'vue-js-modal';
import {
  accumulatedForIndex,
  toEthUnit,
  nctBalanceOf,
  getNFTName,
  isNameReserved,
  getNFTOwner,
  getTimestampFromBlock,
  changeNFTName,
  waitForTransaction,
  claimNCT,
} from '../helpers/contract.js';
import { NAME_CHANGE_PRICE, GRAVEYARD_ADDRESS } from '@/assets/constants';
import LoadingButtonContent from '@/components/LoadingButtonContent';
import Tabs from '@/components/tab/Tabs';
import Tab from '@/components/tab/Tab';
import {
  getMaskById,
  getNameHistoryById,
  getSaleHistoryById,
} from '../helpers/database.worker';
import { STARTING_INDEX } from '../assets/constants';
import { BigNumber } from 'ethers';

Vue.use(vmodal);

export default {
  name: 'Detail',
  components: { LoadingButtonContent, Tabs, Tab },
  data() {
    return {
      accumulatedNCT: 0,
      nctBalance: 0,
      claimEnabled: true,
      changeNameEnabled: true,
      nameValidation: '',
      name: '',
      newName: '',
      owner: '',
      mask: {},
      GRAVEYARD_ADDRESS,
      NAME_CHANGE_PRICE,
      nameHistory: undefined,
      saleHistory: undefined,
    };
  },
  async mounted() {
    this.mask = await getMaskById(this.$route.params.id);
    await this.updateNCTState();
  },
  methods: {
    revealedMaskIndex: function(nftIndex) {
      return (Number(nftIndex) + STARTING_INDEX) % 16384;
    },
    claim: async function(event) {
      this.claimEnabled = false;

      try {
        const tx = await claimNCT([this.$route.params.id]);
        const txHash = tx.hash;
        this.$store.commit('addPendingTx', {
          txHash,
          description: 'Claim NCTs for #' + this.$route.params.id,
        });
        const receipt = await waitForTransaction(tx.hash);
        console.log(receipt);
        this.claimEnabled = true;
        this.updateNCTState();
      } catch (err) {
        console.log(err);
        this.claimEnabled = true;
      }
      event.preventDefault();
    },

    changeName: async function(event) {
      const regex = RegExp('^[a-zA-Z0-9 ]*$');
      if (
        !regex.test(this.newName) ||
        this.newName.length > 25 ||
        this.newName.length < 1
      ) {
        this.nameValidation =
          'Name can only be alphanumeric and cannot be longer than 25 characters';
        return false;
      }
      console.log(this.newName);
      if (
        /\s{2,}/.test(this.newName) ||
        this.newName[0] == ' ' ||
        this.newName[-1] == ' '
      ) {
        this.nameValidation =
          'Name cannot contain consecutive spaces, a trailing or a leading space';
        return false;
      }
      const isNewNameReserved = await isNameReserved(this.newName);
      if (isNewNameReserved === true) {
        this.nameValidation =
          'This name has already been reserved. Please choose a new name';
        return false;
      }

      this.changeNameEnabled = false;

      try {
        const tx = await changeNFTName(this.$route.params.id, this.newName);
        const txHash = tx.hash;
        this.$store.commit('addPendingTx', {
          txHash,
          description: 'Change Name for #' + this.$route.params.id,
        });
        const receipt = await waitForTransaction(tx.hash);
        console.log(receipt);
        this.changeNameEnabled = true;
        this.updateNCTState();
      } catch (err) {
        console.log(err);
        this.changeNameEnabled = true;
      }
      this.$modal.hide('nameChangeForm');
      event.preventDefault();
    },

    async changeNameForm() {
      this.nameValidation = '';
      const nctBalance = await nctBalanceOf();
      if (nctBalance.gte(BigNumber.from(NAME_CHANGE_PRICE))) {
        this.$modal.show('nameChangeForm');
      } else {
        this.$modal.show('insufficientNCT');
      }
    },

    updateNCTState: async function() {
      const accumulated = await accumulatedForIndex(this.$route.params.id);
      this.owner = await getNFTOwner(this.$route.params.id);
      this.name = (await getNFTName(this.$route.params.id)) || 'Unnamed';
      this.accumulatedNCT = Number(toEthUnit(accumulated)).toFixed(2);
      this.nctBalance = Number(toEthUnit(await nctBalanceOf())).toFixed(2);
    },

    getNameHistory: async function() {
      if (this.nameHistory) return;
      const nameHistoryResponse = await getNameHistoryById(
        this.$route.params.id,
      );
      if (nameHistoryResponse) {
        for (let i = nameHistoryResponse.length - 1; i >= 0; i--) {
          const timestamp = await getTimestampFromBlock(
            nameHistoryResponse[i]['block-number'],
          );
          if (!this.nameHistory) this.nameHistory = [];
          this.nameHistory.push({
            name: nameHistoryResponse[i].name,
            timestamp: new Date(timestamp * 1000).toDateString(), // Multiply 1000 to conver to milliseconds
          });
        }
      }
      if (!this.nameHistory) this.nameHistory = [];
    },

    getSaleHistory: async function() {
      if (this.saleHistory) return;
      const saleHistoryResponse = await getSaleHistoryById(
        this.$route.params.id,
      );
      if (saleHistoryResponse) {
        const saleHistoryResult = saleHistoryResponse.asset_events;
        for (let i = saleHistoryResult.length - 1; i >= 0; i--) {
          const item = saleHistoryResult[i];
          const timestamp = item.transaction
            ? item.transaction['timestamp']
            : new Date();
          if (!this.saleHistory) this.saleHistory = [];
          this.saleHistory.push({
            total_price: Number(toEthUnit(item.total_price)).toFixed(2) || 0,
            timestamp: new Date(timestamp).toDateString(),
          });
        }
      }
      if (!this.saleHistory) this.saleHistory = [];
      this.saleHistory.reverse();
    },
  },
};
</script>

<style></style>
