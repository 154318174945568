<template>
  <div id="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-8">
              <div class="page-title has-description">
                <h1>Owner</h1>
                <div id="identicon" style="width: 70px; height: 70px"></div>
                <p id="identicon-text">
                  {{ this.$route.params.id }}
                </p>
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 170px">
              <p>
                <b style="font-weight: 500">Wallet NCT</b>: {{ nctBalance }} NCT
              </p>
              <p>
                <b style="font-weight: 500">Accumulated NCT</b>:
                <span v-if="totalAccumulatedLoading === false">
                  {{ totalAccumulated }}
                </span>
                <span v-else>
                  <div class="loading-circle"></div>
                </span>
              </p>
            </div>
          </div>
          <!-- Portfolio Wrap -->
          <div class="portfolio-wrap">
            <!-- Portfolio Filter -->
            <div class="filters">
              <ul id="filters">
                <li class="active" data-filter="*" style="font-size: 18px">
                  Owned Hashmasks
                  <span class="number-of-masks">{{ items.length }}</span>
                </li>
              </ul>
            </div>
            <!-- Portfolio Filter -->
            <!-- Portfolio Items -->
            <div
              id="portfolio-container"
              class="portfolio-container portfolio-overlay dark text-overlay centered row isotope"
            >
              <div v-if="!items.length">
                <div class="alert alert-warning" role="alert">
                  This account does not own any hashmasks
                </div>
              </div>
              <div
                class="portfolio-item col-md-3 webdesign"
                v-for="item in items"
                :key="item.index"
              >
                <router-link
                  :to="{ name: 'detail', params: { id: item.index } }"
                >
                  <div class="portfolio-content">
                    <div class="portfolio-img-content">
                      <img
                        v-bind:src="
                          'https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/' +
                            revealedMaskIndex(item.index) +
                            '.png'
                        "
                      />
                    </div>
                    <div class="portfolio-text-content">
                      <div class="portfolio-text">
                        <h3>#{{ item.index }}</h3>
                        <h3>{{ item.name || 'Unnamed' }}</h3>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <!-- Portfolio Items Ends -->
          </div>
          <!-- Portfolio Wrap Ends -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  tokenOfOwnerByIndex,
  hashmasksBalanceOf,
  accumulatedForIndex,
  toEthUnit,
  getNFTName,
  nctBalanceOf,
  claimNCT,
  waitForTransaction,
} from '../helpers/contract.js';
import * as blockies from '../../public/js/identicon.js';
import { BigNumber } from 'ethers';
import router from '../router/index.js';
import { STARTING_INDEX } from '../assets/constants';

export default {
  name: 'Detail',
  data() {
    return {
      items: [],
      totalAccumulated: 0,
      nctBalance: 0,
      claimEnabled: true,
      totalAccumulatedLoading: false,
      router,
    };
  },
  async mounted() {
    const icon = blockies.create({
      // All options are optional
      seed: this.$route.params.id, // seed used to generate icon data, default: random
      color: '#dfe', // to manually specify the icon color, default: random
      bgcolor: '#aaa', // choose a different background color, default: random
      size: 25, // width/height of the icon in blocks, default: 8
      scale: 3, // width/height of each block in pixels, default: 4
      spotcolor: '#000', // each pixel has a 13% chance of being of a third color,
      // default: random. Set to -1 to disable it. These "spots" create structures
      // that look like eyes, mouths and noses.
    });
    document.getElementById('identicon').appendChild(icon); // Append <li> to <ul> with id="myList"

    await this.updateNCTState();
  },

  methods: {
    revealedMaskIndex: function(nftIndex) {
      return (Number(nftIndex) + STARTING_INDEX) % 16384;
    },
    claim: async function(event) {
      this.claimEnabled = false;

      try {
        const tx = await claimNCT(this.items.map((a) => a.index));
        const txHash = tx.hash;
        this.$store.commit('addPendingTx', {
          txHash,
          description: 'Claim All NCT',
        });
        const receipt = await waitForTransaction(tx.hash);
        console.log(receipt);
        this.claimEnabled = true;
        this.updateNCTState();
      } catch (err) {
        console.log(err);
        this.claimEnabled = true;
      }
      event.preventDefault();
    },

    updateNCTState: async function() {
      this.nctBalance = Number(
        toEthUnit(await nctBalanceOf(this.$route.params.id)),
      ).toFixed(2);
      const balance = await hashmasksBalanceOf(this.$route.params.id);
      let totalAccumulated = BigNumber.from(0);

      this.totalAccumulatedLoading = true;
      for (let i = 0; i < balance; i++) {
        const index = await tokenOfOwnerByIndex(i, this.$route.params.id);
        const name = await getNFTName(index);
        const accumulated = await accumulatedForIndex(index);
        totalAccumulated = totalAccumulated.add(accumulated);
        if (this.items.length < balance) {
          this.items.push({ index, name });
        }
      }
      this.totalAccumulated = Number(toEthUnit(totalAccumulated)).toFixed(2);
      this.totalAccumulatedLoading = false;
    },
  },
};
</script>

<style></style>
